import * as React from "react";
import { useFetch, useFetchDataUrl } from "../utils";
import { User } from "../api/user-api";
import * as UserApi from "../api/user-api";
import { Loading } from "../components";
import { Container, Typography, Button, TextField } from "@mui/material";
import { useParams } from "react-router";
import UserWorkouts from "./user-workouts";
import UserTransactions from "./user-transactions";
import UserMemberships from "./user-memberships";
import UserPreferences from "./user-preferences";
import makeStyles from "@mui/styles/makeStyles";
import UserWorkoutLogList from "../components/user-workout-log";
import { ProfileDeleteRequestDialog } from "./profile-delete-request-dialog";
import { useToken } from "../token-provider";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/lab";

const RecapImage = ({ profileId }: { profileId: number }) => {
  const { data } = useFetchDataUrl(`/users/${profileId}/recap/2023.png`);

  return <img src={data} alt="2023 Recap" />;
};

const Recap = ({ profileId }: { profileId: number }) => {
  const loadRecap = () => {
    setElement(<RecapImage profileId={profileId} />);
  };
  const [element, setElement] = React.useState<React.ReactNode>(
    <Button
      variant="contained"
      color="primary"
      component="span"
      onClick={loadRecap}
    >
      Load Recap
    </Button>
  );
  return <>{element}</>;
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(3),
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cancelBtn: {
      color: "red",
      borderColor: "red",
    },
  };
});

const UserDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useFetch<User>(`/users/${id}`);
  const token = useToken();
  const classes = useStyles();
  const [isoCode, setIsoCode] = React.useState("");
  const Brand = process.env.REACT_APP_BRAND;
  const [confirmDeleteRequest, setConfirmDeleteRequest] = React.useState(false);
  const [updateStandardGrant, setUpdateStandardGrant] = React.useState(false);

  React.useEffect(() => {
    if (data?.grantStandardUntil) {
      setUpdateStandardGrant(false);
    }
  }, [data, data?.grantStandardUntil]);

  const { control } = useForm({
    defaultValues: {
      submitGrant: null,
    },
  });
  if (!data && !error) {
    return <Loading />;
  } else if (data) {
    return (
      <>
        <Container className={classes.container}>
          <Typography variant="h2">
            {data.firstName} {data.lastName} - {data.displayName} ({data.email})
          </Typography>
          {Brand === "litesport" && (
            <>
              <br></br>
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmDeleteRequest(true);
                }}
                className={classes.cancelBtn}
              >
                Delete Profile Request
              </Button>
            </>
          )}
          <Typography className={classes.subtitle} variant="h4">
            Memberships
          </Typography>
          {Brand === "litesport" &&
          !updateStandardGrant &&
          data.grantStandardUntil ? (
            <>
              <Typography>
                <p></p>
                Standard Access Granted until {data.grantStandardUntil}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  UserApi.removeGrantAccess(token, data.id).then((response) => {
                    data.grantStandardUntil = undefined;
                    setUpdateStandardGrant(true);
                  });
                }}
                className={classes.cancelBtn}
              >
                Remove Grant
              </Button>
            </>
          ) : (
            <>
              <p></p>
              {updateStandardGrant ? (
                <>
                  <Controller
                    name={"submitGrant"}
                    control={control}
                    render={(props) => {
                      return (
                        <DatePicker
                          label="Grant Standard Until"
                          value={props.value}
                          onChange={(newValue) => {
                            props.onChange(newValue.setHours(0, 0, 0, 0));
                            UserApi.setGrantAccess(
                              token,
                              data.id,
                              newValue.toISOString()
                            ).then((response) => {
                              data.grantStandardUntil = newValue.toISOString();
                              setUpdateStandardGrant(false);
                            });
                          }}
                          renderInput={(inputProps) => (
                            <TextField {...inputProps} />
                          )}
                        />
                      );
                    }}
                  />
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUpdateStandardGrant(true);
                  }}
                  className={classes.cancelBtn}
                >
                  Grant Standard Access
                </Button>
              )}
            </>
          )}
          <UserMemberships profileId={data.id} />
          <Typography className={classes.subtitle} variant="h4">
            Recap
          </Typography>
          <Recap profileId={data.id} />
          <Typography className={classes.subtitle} variant="h4">
            Workouts
          </Typography>
          <UserWorkouts profileId={data.id} />
          <Typography className={classes.subtitle} variant="h4">
            Log
          </Typography>
          <UserWorkoutLogList profileId={data.id} />
          <Typography className={classes.subtitle} variant="h4">
            Preferences
          </Typography>
          <UserPreferences profileId={data.id} />
          <Typography className={classes.subtitle} variant="h4">
            Transactions
          </Typography>
          <UserTransactions profileId={data.id} />
        </Container>
        <ProfileDeleteRequestDialog
          open={confirmDeleteRequest}
          onConfirm={() => {
            UserApi.requestProfileDelete(token, data.uuid, isoCode).then(
              (response) => {
                if (response.ok) {
                  setConfirmDeleteRequest(false);
                } else {
                  // setErrorMessage(
                  //   "Could not unlink device. Contact dev team for support."
                  // );
                }
              }
            );
          }}
          onClose={() => setConfirmDeleteRequest(false)}
          onIsoCodeChange={(isoCode: string) => setIsoCode(isoCode)}
        />
      </>
    );
  } else {
    return (
      <Container className={classes.container}>
        <Typography variant="h2">User not found</Typography>
      </Container>
    );
  }
};

export default UserDetails;
